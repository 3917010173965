import styled from 'styled-components'
import { Grids } from 'Shared/consts/ConstantsCommon'

export const ImageLinkContainer = styled.div`
    border: ${ Grids ? '1px solid red' : 'none' };
    display:flex; flex-direction:column; align-items:flex-start;
  `

export const Icon = styled.div`
    border: ${ Grids ? '1px solid orange' : 'none' };
    width:67%; 

    svg, img {
      width:100%; height:auto;
      transition:.4s;

      &:hover{ transform:scale(1.025); }
    }
  `

export const Description = styled.div`
    border: ${ Grids ? '1px solid orange' : 'none' };
    margin-top:10px; color:#828282;
  `